// Device.js

import React, { useState, useEffect } from "react";
import FillGuage from "./FillGuage";
import { useAuth0 } from "@auth0/auth0-react";
import ReactTooltip from 'react-tooltip';

export default function Device(props) {
	
	const [state, setState] = useState({
		amEditing: false,
		devicesConfigData: null,
		error: null,
		data: props.row,
		newVaaskId:props.row.vaaskId,
		newBuilding:props.row.building,
		newFloor:props.row.floor,
		newDesc:props.row.desc,
		newFillVolLSetting:props.row.fillVolLSetting,
		newDispenseVolmLSetting:null,
		unpair:'',
		unpairEnabled:false,
		// initialState: structuredClone(props.row),
		loadingInitiated: false,
		deviceIDError: false,
	});

	const {
		getAccessTokenSilently,
	} = useAuth0();
	
	var apiRoot = 'api.vaask.com';
	if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' || window.location.hostname === 'portal-dev.vaask.com') {
		apiRoot = 'api-dev.vaask.com';
	}
	
	function handleEditClick() {
		setState({
			...state,
			amEditing: !state.amEditing
		})
	}
		
	function handleSaveClick() {	
		var newDispenseVolmLSetting = state.data.dispenseVolmLSetting;
		if (state.newDispenseVolmLSetting) {
			newDispenseVolmLSetting = state.devicesConfigData.dispenseVolumeOptions[state.newDispenseVolmLSetting - 1];
		}
		const newData = {
			id: state.data.id,
			vaaskId: state.newVaaskId,
				building: state.newBuilding,
			floor: state.newFloor,
			desc: state.newDesc,
			fillVolLSetting: state.newFillVolLSetting,
			dispenseVolmLSetting: newDispenseVolmLSetting
		};
		
		var callback = function (worked) {
			if (worked === false) {
				setState(prevState => ({
					...prevState,
					deviceIDError: true,
				}));
			}
			else {
				setState(prevState => ({
					...prevState,
					deviceIDError: false,
				}));
			}
		}
		
		props.saveDevice(state.data, newData, callback);
	}
		
	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value
		});
	}
		
	function handleUnpairChange(evt) {
		const value = evt.target.value;
		if (value.toLowerCase() === 'unpair') {
			setState({
				...state,
				unpairEnabled: true,
				[evt.target.name]: value
			});
		}
		else {
			setState({
				...state,
				unpairEnabled: false,
				[evt.target.name]: value
			});
		}
	}
		
	function handleUnpairClick() {
		setState({
			...state,
			unpairEnabled: false,
			amEditing: !state.amEditing,
			unpair:''
		});
		props.unpairDevice(state.data.id);
	}
		
	useEffect(() => {
		if (state.loadingInitiated === false) {
			setState(prevState => ({
				...prevState,
				loadingInitiated: true,
			}));
			(async () => {
				try {
					const token = await getAccessTokenSilently();

					const response = await fetch(`https://${apiRoot}/api/device/defaults`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});

					const responseData = await response.json();
					setState({
						...state,
						devicesConfigData: responseData,
						loadingInitiated: true,
					});
				} catch (error) {
					setState({
						...state,
						error: error.error,
						loadingInitiated: true,
					});
				}
			})();
		}
	}, [state, getAccessTokenSilently, apiRoot]);
		
	// // 0 bars
	// let wifi = <>
	// 	<svg width="20" height="100%" viewBox="0 0 17 13">
	// 	<g transform="matrix(1,0,0,1,-3.07422,-4.68018)">
	// 	<path d="M11.45,14.047C12.243,14.047 12.889,14.708 12.889,15.523C12.889,16.338 12.244,17 11.45,17C10.656,17 10.011,16.339 10.011,15.523C10.011,14.709 10.655,14.047 11.45,14.047Z" fill="#dddddd"/>
	// 	<path d="M11.45,6.242C8.594,6.242 6.054,7.636 4.439,9.795C4.183,10.136 3.705,10.201 3.372,9.939C3.039,9.677 2.976,9.185 3.231,8.844C5.125,6.313 8.103,4.68 11.45,4.68C14.796,4.68 17.775,6.313 19.668,8.844C19.924,9.185 19.861,9.677 19.528,9.939C19.195,10.201 18.716,10.136 18.46,9.795C16.845,7.635 14.305,6.242 11.45,6.242Z" fill="#dddddd"/>
	// 	<path d="M11.45,10.957C9.885,10.957 8.505,11.771 7.687,13.007C7.451,13.364 6.977,13.457 6.629,13.215C6.282,12.973 6.191,12.487 6.427,12.13C7.519,10.48 9.362,9.395 11.45,9.395C13.539,9.395 15.382,10.48 16.473,12.13C16.709,12.487 16.618,12.973 16.271,13.215C15.923,13.457 15.449,13.364 15.213,13.007C14.395,11.771 13.015,10.957 11.45,10.957Z" fill="#dddddd"/>
	// 	</g>
	// 	</svg>
	// </>;
	
	// 0 bars
	let wifi = <>
		Not connected
	</>;
	if (state.data.wifiSignal < 0 && state.data.wifiSignal < -75) { // no bars
		wifi = <>
			<svg width="20" height="100%" viewBox="0 0 17 13">
			<g transform="matrix(1,0,0,1,-3.07422,-4.68018)">
			<path d="M11.45,14.047C12.243,14.047 12.889,14.708 12.889,15.523C12.889,16.338 12.244,17 11.45,17C10.656,17 10.011,16.339 10.011,15.523C10.011,14.709 10.655,14.047 11.45,14.047Z" fill="#dddddd"/>
			<path d="M11.45,6.242C8.594,6.242 6.054,7.636 4.439,9.795C4.183,10.136 3.705,10.201 3.372,9.939C3.039,9.677 2.976,9.185 3.231,8.844C5.125,6.313 8.103,4.68 11.45,4.68C14.796,4.68 17.775,6.313 19.668,8.844C19.924,9.185 19.861,9.677 19.528,9.939C19.195,10.201 18.716,10.136 18.46,9.795C16.845,7.635 14.305,6.242 11.45,6.242Z" fill="#dddddd"/>
			<path d="M11.45,10.957C9.885,10.957 8.505,11.771 7.687,13.007C7.451,13.364 6.977,13.457 6.629,13.215C6.282,12.973 6.191,12.487 6.427,12.13C7.519,10.48 9.362,9.395 11.45,9.395C13.539,9.395 15.382,10.48 16.473,12.13C16.709,12.487 16.618,12.973 16.271,13.215C15.923,13.457 15.449,13.364 15.213,13.007C14.395,11.771 13.015,10.957 11.45,10.957Z" fill="#dddddd"/>
			</g>
			</svg>
		</>;
	}
	else if (state.data.wifiSignal < 0 && state.data.wifiSignal <= -65 && state.data.wifiSignal >= -75) { // 1 bar
		wifi = <>
			<svg width="20" height="100%" viewBox="0 0 17 13">
			<g transform="matrix(1,0,0,1,-3.07422,-4.68018)">
			<path d="M11.45,14.047C12.243,14.047 12.889,14.708 12.889,15.523C12.889,16.338 12.244,17 11.45,17C10.656,17 10.011,16.339 10.011,15.523C10.011,14.709 10.655,14.047 11.45,14.047Z" fill="#000000"/>
			<path d="M11.45,6.242C8.594,6.242 6.054,7.636 4.439,9.795C4.183,10.136 3.705,10.201 3.372,9.939C3.039,9.677 2.976,9.185 3.231,8.844C5.125,6.313 8.103,4.68 11.45,4.68C14.796,4.68 17.775,6.313 19.668,8.844C19.924,9.185 19.861,9.677 19.528,9.939C19.195,10.201 18.716,10.136 18.46,9.795C16.845,7.635 14.305,6.242 11.45,6.242Z" fill="#dddddd"/>
			<path d="M11.45,10.957C9.885,10.957 8.505,11.771 7.687,13.007C7.451,13.364 6.977,13.457 6.629,13.215C6.282,12.973 6.191,12.487 6.427,12.13C7.519,10.48 9.362,9.395 11.45,9.395C13.539,9.395 15.382,10.48 16.473,12.13C16.709,12.487 16.618,12.973 16.271,13.215C15.923,13.457 15.449,13.364 15.213,13.007C14.395,11.771 13.015,10.957 11.45,10.957Z" fill="#dddddd"/>
			</g>
			</svg>
		</>;
	}
	else if (state.data.wifiSignal < 0 && state.data.wifiSignal <= -55 && state.data.wifiSignal > -65) { // 2 bars
		wifi = <>
			<svg width="20" height="100%" viewBox="0 0 17 13">
			<g transform="matrix(1,0,0,1,-3.07422,-4.68018)">
			<path d="M11.45,14.047C12.243,14.047 12.889,14.708 12.889,15.523C12.889,16.338 12.244,17 11.45,17C10.656,17 10.011,16.339 10.011,15.523C10.011,14.709 10.655,14.047 11.45,14.047Z" fill="#000000"/>
			<path d="M11.45,6.242C8.594,6.242 6.054,7.636 4.439,9.795C4.183,10.136 3.705,10.201 3.372,9.939C3.039,9.677 2.976,9.185 3.231,8.844C5.125,6.313 8.103,4.68 11.45,4.68C14.796,4.68 17.775,6.313 19.668,8.844C19.924,9.185 19.861,9.677 19.528,9.939C19.195,10.201 18.716,10.136 18.46,9.795C16.845,7.635 14.305,6.242 11.45,6.242Z" fill="#dddddd"/>
			<path d="M11.45,10.957C9.885,10.957 8.505,11.771 7.687,13.007C7.451,13.364 6.977,13.457 6.629,13.215C6.282,12.973 6.191,12.487 6.427,12.13C7.519,10.48 9.362,9.395 11.45,9.395C13.539,9.395 15.382,10.48 16.473,12.13C16.709,12.487 16.618,12.973 16.271,13.215C15.923,13.457 15.449,13.364 15.213,13.007C14.395,11.771 13.015,10.957 11.45,10.957Z" fill="#000000"/>
			</g>
			</svg>
		</>;
	}
	else if (state.data.wifiSignal < 0) { // 3 bars
		wifi = <>
			<svg width="20" height="100%" viewBox="0 0 17 13">
			<g transform="matrix(1,0,0,1,-3.07422,-4.68018)">
			<path d="M11.45,14.047C12.243,14.047 12.889,14.708 12.889,15.523C12.889,16.338 12.244,17 11.45,17C10.656,17 10.011,16.339 10.011,15.523C10.011,14.709 10.655,14.047 11.45,14.047Z" fill="#000000"/>
			<path d="M11.45,6.242C8.594,6.242 6.054,7.636 4.439,9.795C4.183,10.136 3.705,10.201 3.372,9.939C3.039,9.677 2.976,9.185 3.231,8.844C5.125,6.313 8.103,4.68 11.45,4.68C14.796,4.68 17.775,6.313 19.668,8.844C19.924,9.185 19.861,9.677 19.528,9.939C19.195,10.201 18.716,10.136 18.46,9.795C16.845,7.635 14.305,6.242 11.45,6.242Z" fill="#000000"/>
			<path d="M11.45,10.957C9.885,10.957 8.505,11.771 7.687,13.007C7.451,13.364 6.977,13.457 6.629,13.215C6.282,12.973 6.191,12.487 6.427,12.13C7.519,10.48 9.362,9.395 11.45,9.395C13.539,9.395 15.382,10.48 16.473,12.13C16.709,12.487 16.618,12.973 16.271,13.215C15.923,13.457 15.449,13.364 15.213,13.007C14.395,11.771 13.015,10.957 11.45,10.957Z" fill="#000000"/>
			</g>
			</svg>
		</>;
	}
	
	let dispenserState = <>
		Active
	</>;
	if (state.data.isPaused === true) {
		dispenserState = <>
			Paused
		</>;
	}
	// if (state.data.wifiSignal === 0) {
	// 	dispenserState = <>
	// 		Not connected
	// 	</>;
	// }
	
	let lastUsed = <>
		Never
	</>;
	if (state.data.lastUpdatedDate !== '') {
		// const unixTime = Date.parse(state.data.lastUpdatedDate);
		let lastUsedDate = new Date(state.data.lastUpdatedDate);
		let lastUsedDateDate = lastUsedDate.toLocaleDateString();
		let lastUsedDateTime = lastUsedDate.toLocaleTimeString();
		lastUsed = <>
		{lastUsedDateDate}<br/>{lastUsedDateTime}
		</>;
	}
	
	return (
		<div id="device" className="aside-panel">
		{!state.amEditing && (
			<>
				<span className="device-overlay-close-wrap" onClick={() => props.deSelect()}></span>
				<span className="device-overlay-close" onClick={() => props.deSelect()}>
				<svg width="17" height="17" viewBox="0 0 20 20" fill="none">
				<path d="M1 1L19 19" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M19 1L1 19" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				</span>
				<h2>Dispenser Details</h2>
				<div className="device-actions">			
				<span onClick={handleEditClick}>
				<svg width="20" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M20.7774 1.28292C19.0668 -0.427641 16.2832 -0.427641 14.5727 1.28292L1.48329 14.3734C1.36165 14.4951 1.27911 14.6493 1.24544 14.8177L0.0170732 20.9617C-0.0404887 21.2463 0.0496565 21.5417 0.254925 21.7469C0.418922 21.9099 0.640479 22 0.868552 22C0.925027 22 0.982592 21.9946 1.03907 21.9826L7.18312 20.7543C7.35146 20.7206 7.5057 20.638 7.62734 20.5164L20.7787 7.365C22.4546 5.68919 22.4546 2.9609 20.7777 1.28295L20.7774 1.28292ZM7.01233 18.6744L5.75357 17.4156C6.61701 16.5272 8.78473 14.3617 13.7655 9.46323C14.1076 9.12656 14.1119 8.57699 13.7752 8.23487C13.4386 7.89166 12.889 7.88732 12.5469 8.22509C7.80191 12.8919 5.57094 15.0848 4.53915 16.2014L3.32599 14.9882L13.7696 4.54353L17.4558 8.22973L7.01233 18.6744ZM2.63103 16.7498L5.24957 19.3684L1.97625 20.0233L2.63103 16.7498ZM19.5501 6.13663L18.6856 7.00116L14.9994 3.31495L15.802 2.51235C16.8359 1.47949 18.5161 1.47949 19.551 2.51343C20.548 3.51153 20.548 5.1374 19.5499 6.13653L19.5501 6.13663Z" fill="black"/>
				</svg>
				</span>
				</div>
				<div className="device-summary">
					<p><span>Building</span> {state.data.building}</p>
					<p><span>Floor</span> {state.data.floor}</p>
					<p><span>Dispenser ID</span> {state.data.vaaskId}</p>
					<p><span>Description</span> {state.data.desc}</p>
					<p><span>Dispenser State</span> {dispenserState}</p>
					<div><span>Dispenser Status</span> <FillGuage expirationDate={state.data.expirationDate} daysToEmpty={state.data.daysToEmpty} isPaused={state.data.isPaused} fillLevel={state.data.fillLevel} fillVolLSetting={state.data.fillVolLSetting} dispenseVolmLSetting={state.data.dispenseVolmLSetting} isEmpty={state.data.isEmpty} wifiSignal={state.data.wifiSignal} /></div>
					<p><span>Wi-Fi Strength</span> 
						{wifi}
					</p>
					<p><span>Last Updated<span className="help" data-tip="When the dispenser was last used by a person">?</span></span> {lastUsed}</p>
					<p><span>Lifetime Dispenses</span> {state.data.lifetimeCount}</p>
					<p><span>Max Fill Level</span> {state.data.fillVolLSetting} L</p>
					<p><span>Dispense Volume</span> {state.data.dispenseVolmLSetting} mL</p>
				</div>
				<ReactTooltip />
			</>
		)}
		{state.amEditing && state.devicesConfigData && (
			<>
				<span className="device-overlay-close-wrap" onClick={() => props.deSelect()}></span>
				<h2>Edit Dispenser</h2>
				<div className="device-actions">
				<button onClick={handleSaveClick}>Save</button>
				<span onClick={handleEditClick}>
					<svg width="17" height="17" viewBox="0 0 20 20" fill="none">
						<path d="M1 1L19 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M19 1L1 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</span>
				</div>
				<div className="field"><label><span>Building</span> <input type="text" value={state.newBuilding? state.newBuilding : ''} name="newBuilding" onChange={handleChange}/></label><span className="help" data-tip="Use this field if your managed space has multiple buildings or wings">?</span></div>
				<div className="field"><label><span>Floor</span> <input type="text" value={state.newFloor? state.newFloor : ''} name="newFloor" onChange={handleChange} /></label><span className="help" data-tip="Descriptive or numeric floor designator">?</span></div>
				<div className="field">
					<label><span>Vaask ID</span> <input type="text" value={state.newVaaskId? state.newVaaskId : ''} name="newVaaskId" onChange={handleChange} maxlength="5" /></label><span className="help" data-tip="Enter a 2 to 5 character identifier that is unique to your space. i.e. nearest room or column number">?</span>
					{state.deviceIDError && (
						<span className="vaask-id-unique-error">Must be unique</span>
					)}
				</div>
				<div className="field"><label><span>Description</span> <input type="text" value={state.newDesc? state.newDesc : ''} name="newDesc" onChange={handleChange} /></label><span className="help" data-tip="Short description of where this dispenser is located. i.e. &quot;blue wall by elevator&quot;">?</span></div>
				<p className="label"><strong>Fill Volume</strong> <span className="help" data-tip="Fire code in many juristictions stipulates that a hand sanitizer dispenser should be filled to 1.2L max in spaces open to corridors">?</span></p>

				<div onChange={handleChange}>
					{state.devicesConfigData.fillVolumeOptions.map((level, i) => {
						return (<div className="radio-field" key={"radio-"+i}><label>{level} L Cartridge <input type="radio" name="newFillVolLSetting" value={level} checked={String(state.newFillVolLSetting) === String(level)} onChange={handleChange}/></label></div>);
					})}
				</div>
				<p className="label"><strong>Dispense Volume</strong> <span className="help" data-tip="Different situations may require different amounts of sanitizer. CDC recommends using enough sanitizer to coat hands for 20 seconds">?</span></p>
				<div className="slider">
					<input type="range" min="1" max={state.devicesConfigData.dispenseVolumeOptions.length} id="dispence-volume" name="newDispenseVolmLSetting" step="1" defaultValue={ state.devicesConfigData.dispenseVolumeOptions.indexOf(state.data.dispenseVolmLSetting)+1 } onChange={handleChange}/>
					<div className="slider-steps">
						{state.devicesConfigData.dispenseVolumeOptions.map((level, i) => {
							return (<div className="slider-label" key={"radio-"+i}>{level} mL</div>);
						})}
					</div>
				</div>

				<p className="label"><strong>Unpair Dispenser</strong></p>
				<div className="field"><label><span>Type &quot;unpair&quot;</span> <input type="text" name="unpair" value={state.unpair} onChange={handleUnpairChange}/></label></div>
				<button onClick={handleUnpairClick} className="secondary" disabled={!state.unpairEnabled}>Unpair Dispenser</button>
				<ReactTooltip />
			</>
		)}
	</div>
	);
}