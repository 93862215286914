// FillGuage.js

import React from "react";

export default function FillGuage({expirationDate, daysToEmpty, isPaused, fillLevel, fillVolLSetting, dispenseVolmLSetting, isEmpty, wifiSignal}) {
	
	// const fillVolmLSetting = fillVolLSetting * 1000;
	let colour = '#f00';
	let levelText = '';
	// const left_pc = (fillLevel / fillVolmLSetting) * 100;
	var left_pc = fillLevel * 100;
	if (left_pc < 10) {
		left_pc = 10;
	}
	
	var expiresInDays = null;
	if (expirationDate !== null && expirationDate !== '') {
		var now = Date.now();

		let expires = new Date(expirationDate);
		if (expirationDate) {
			// One day in milliseconds
			const oneDay = 1000 * 60 * 60 * 24;

			// Calculating the time difference between two dates
			const diffInTime = expires - now;
			
			// Calculating the no. of days between two dates
			expiresInDays = Math.round(diffInTime / oneDay);
		}
	}
	
	if (expiresInDays !== null) {
		if (expiresInDays >= 0 && (expiresInDays < daysToEmpty || daysToEmpty === -1)) {
			daysToEmpty = expiresInDays;
		}
		else if (expiresInDays < 0) {
			daysToEmpty = 0;
		}
	}
	
	if (isEmpty === true) {
		levelText = 'Empty';
		colour = '#f00';
		left_pc = 10;
	}
	else if (daysToEmpty <= -50) {
		levelText = 'Refill Error';
		colour = '#95d2b9';
		if (left_pc <= 10) {
			colour = '#f00';
		}
	}
	else if (daysToEmpty < -1) {
		levelText = 'Low sanitizer';
		colour = '#95d2b9';
		if (left_pc <= 10) {
			colour = '#f00';
		}
	}
	else if (daysToEmpty === -1) {
		levelText = '';
		colour = '#95d2b9';
		if (left_pc <= 10) {
			colour = '#f00';
		}
	}
	else if (daysToEmpty === 1) {
		levelText = '1 Day Left';
		colour = '#f00';
	}
	else {
		levelText = String(daysToEmpty)+' Days Left';
		if (daysToEmpty >= 0 && daysToEmpty <= 7) {
			colour = '#f00';
		}
		else {
			colour = '#95d2b9';
		}
	}
	if (wifiSignal === 0) {
		// levelText = 'Not connected';
		// levelText = '';
		colour = '#ccc';
	}
	if (isPaused) {
		levelText = 'Paused';
		colour = '#ccc';
	}
	
	
	// else if (left_pc <= 10) {
	// 	levelText = '1 Day Left';
	// 	colour = '#f00';
	// }
	// else {
	// 	const days = Math.round(left_pc/5);
	// 	levelText = days+' Days Left';
	// 	colour = '#ddd';
	// }
  
  return (
    <div>
		<div className="track"><div className="bar" style={{width:left_pc+'%',backgroundColor:colour}}></div></div>
		<span className="track-label">{levelText}</span>
	</div>
  );
}