import React from "react";

const Loading = () => (
	<div className="spinner">
		<div className="load">
			<hr/><hr/><hr/><hr/>
		</div>
	</div>
);

export default Loading;
