// Table.js

import React, { useState} from "react";
import { useTable, useSortBy } from "react-table";

export default function Table({ columns, data, selector, savedDevice }) {
		const [state, setState] = useState({
		selected:null
		});
	
	
	// Use the useTable Hook to send the columns and data to build the table
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
		prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
	} = useTable({
		columns,
		data
		},
		useSortBy
	);
	
	const handleSelect = (row) => {
		setState({...state, selected:row})
		selector(row.original);
	};
	
	/* 
		Render the UI for your table
		- react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
	*/
	return (
		<table {...getTableProps()}>
			<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}
								className={
									column.isSorted
										? column.isSortedDesc
											? "sort-desc"
											: "sort-asc"
										: ""
								}>{column.render("Header")}</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()} onClick={() => handleSelect(row)} className={`${state.selected === row ? "active-row" : ""} ${savedDevice === row.original.id ? "saved" : ""}`}>
							{row.cells.map(cell => {
								return <td {...cell.getCellProps()}><div className="ellipsis">{cell.render("Cell")}</div></td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}