import React, {useRef, useState, Fragment} from "react";
// import {useParams} from "react-router-dom";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";

export const SettingsComponent = ({userData, dispatchAccessKey, checkAccessKey, signupProcessor, userComplete, deleteProcessor}) => {
	// const { user } = useAuth0();
	
	// const { accessKey } = useParams();
	const inputRef = useRef(null);
	
	const [state, setState] = useState({
		formDisabled: userComplete,
		mode: 'normal',
		validationCode: null,
		showFormSuccess: false,
		userData: userData,
		orgName: userData.organization? userData.organization.orgName : '',	
		buildingName: userData.organization? userData.organization.buildingName : '',		
		streetAddress: userData.organization? userData.organization.streetAddress : '',		
		city: userData.organization? userData.organization.city : '',		
		state: userData.organization? userData.organization.state : '',	
		postalCode: userData.organization? userData.organization.postalCode : '',		
		country: userData.organization? userData.organization.country : '',	
		managerFirstName: userData.organization? userData.organization.managerFirstName : '',		
		managerLastName: userData.organization? userData.organization.managerLastName : '',	
		managerPhone: userData.organization? userData.organization.managerPhone : '',	
		managerEmail: userData.organization? userData.organization.managerEmail : userData.email,
		isDeleting: false,
	});
	
	const {
		logout,
	} = useAuth0();
	
	function handleSaveClick() {
		let newUser = structuredClone(state);
		delete newUser.userData;
		
		var callback = function (worked) {
			if (worked === true) {
				setState(prevState => ({
					...prevState,
					formDisabled: true,
					showFormSuccess: true
				}));
			}
		}
		
		signupProcessor(newUser, callback);
	}
	
	function handleUnlockClick () {
		if (userComplete === false) {
			handleEnable();
			return;
		}
		// else {
		// 	if (accessKey === 'test') {
		// 		handleEnable();
		// 		return;
		// 	}
		// }
		setState(prevState => ({
			...prevState,
			mode: 'access'
		}));
	}
	
	function handleUnlockKeyClick () {
		var callback = function (worked) {
			if (worked === true) {
				setState(prevState => ({
					...prevState,
					mode: 'key'
				}));
			}
		}
		dispatchAccessKey(callback);
		// need to send email here ...
	}
	
	function handleEnterKeyClick () {
		setState(prevState => ({
			...prevState,
			mode: 'redeem'
		}));
	}
	
	function handleTryUnlockKeyClick () {
		// submit field value here...
		var callback = function (worked) {
			if (worked === true) {
				if (state.isDeleting === true) {
					setState(prevState => ({
						...prevState,
						mode: 'delete',
						isDeleting: false
					}));
					return;
				}
				setState(prevState => ({
					...prevState,
					validationCode: inputRef.current.value
				}));
				handleEnable();
			}
		}
		// if (inputRef.current.value === "test") {
		// 	callback(true);
		// 	return;
		// }
		checkAccessKey(inputRef.current.value, callback);
	}
	
	function handleClose() {
		setState(prevState => ({
			...prevState,
			formDisabled: true
		}));
	}
	
	function handleEnable() {
		setState(prevState => ({
			...prevState,
			formDisabled: false,
			showFormSuccess: false,
			mode: 'normal'
		}));
	}
	
	function handleChange(evt) {
		const value = evt.target.value;
		if (state.showFormSuccess === false) {
			setState(prevState => ({
				...prevState,
				[evt.target.name]: value
			}));
		}
	}
	
	function handleDeleteClick() {
		setState(prevState => ({
			...prevState,
			mode: 'redeem',
			isDeleting: true
		}));
	}
	
	function handleDirectDeleteClick() {		
		setState(prevState => ({
			...prevState,
			mode: 'delete'
		}));
	}
	
	function handleTryDeleteClick() {
		if (inputRef.current.value === "DELETE") {
			var callback = function (worked) {
				if (worked === true) {
					logout({
						returnTo: window.location.origin,
					});
				}
				else {
					setState(prevState => ({
						...prevState,
						mode: 'deletefailed'
					}));
				}
			}
			deleteProcessor(state.validationCode, callback);
		}
	}
	
	function handleUndeleteClick() {
		handleEnable();
	}

	return (
		<>
			<div id="me">
				<main>
					<h2>Settings</h2>
						<div className="panel" id="settings">
							{!userComplete && (
								<div className="alert">
									<h2>Add some more details</h2>
									<p>Before you can add dispensers, we just need some more details</p>
								</div>
							)}
							<div className="actions">
								<button onClick={handleSaveClick} className={state.formDisabled === true? 'disabled' : ''}>Submit</button>
								<span className={state.formDisabled === true? 'disabled' : ''}>
									<svg width="17" height="17" viewBox="0 0 20 20" fill="none" onClick={handleClose}>
										<path d="M1 1L19 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M19 1L1 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
								</span>
								<span className={state.formDisabled === false? 'disabled' : ''}>
									<svg width="20" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleUnlockClick}>
										<path d="M20.7774 1.28292C19.0668 -0.427641 16.2832 -0.427641 14.5727 1.28292L1.48329 14.3734C1.36165 14.4951 1.27911 14.6493 1.24544 14.8177L0.0170732 20.9617C-0.0404887 21.2463 0.0496565 21.5417 0.254925 21.7469C0.418922 21.9099 0.640479 22 0.868552 22C0.925027 22 0.982592 21.9946 1.03907 21.9826L7.18312 20.7543C7.35146 20.7206 7.5057 20.638 7.62734 20.5164L20.7787 7.365C22.4546 5.68919 22.4546 2.9609 20.7777 1.28295L20.7774 1.28292ZM7.01233 18.6744L5.75357 17.4156C6.61701 16.5272 8.78473 14.3617 13.7655 9.46323C14.1076 9.12656 14.1119 8.57699 13.7752 8.23487C13.4386 7.89166 12.889 7.88732 12.5469 8.22509C7.80191 12.8919 5.57094 15.0848 4.53915 16.2014L3.32599 14.9882L13.7696 4.54353L17.4558 8.22973L7.01233 18.6744ZM2.63103 16.7498L5.24957 19.3684L1.97625 20.0233L2.63103 16.7498ZM19.5501 6.13663L18.6856 7.00116L14.9994 3.31495L15.802 2.51235C16.8359 1.47949 18.5161 1.47949 19.551 2.51343C20.548 3.51153 20.548 5.1374 19.5499 6.13653L19.5501 6.13663Z" fill="black"/>
									</svg>
								</span>
					 		</div>
							{userComplete && state.mode === 'access' && (
							<div className="send-access">
								<h3>Editing locked</h3>
								<p>Only {state.managerEmail} can edit settings, using an access key that can be sent to that address</p>
								<button onClick={handleUnlockKeyClick}>Send access key</button>
								<p><span onClick={handleEnterKeyClick} className="link">Use an access key</span></p>
							</div>
							)}
							{userComplete && state.mode === 'key' && (
							<div className="send-access">
								<h3>Editing locked</h3>
								<p>An email has been sent to {state.managerEmail}</p>
								<p><span onClick={handleEnterKeyClick} className="link">Use an access key</span></p>
							</div>
							)}
							{userComplete && state.mode === 'delete' && (
							<div className="send-access">
								<h3>Delete this account</h3>
								<p>Type DELETE (uppercase) to remove this account from our database.</p>
								<p>
									<input type="text" ref={inputRef} className="access-key" />
									<button onClick={handleTryDeleteClick}>Delete</button>
								</p>
								<p><span onClick={handleUndeleteClick} className="link">Do not delete</span></p>
							</div>
							)}
							{userComplete && state.mode === 'deletefailed' && (
							<div className="send-access">
								<h3>Something went wrong</h3>
								<p>We tried to delete your account, but something went amiss. Please reach out to <a href="mailto:help@vaask.com" className="settings-delete-help-link">help@vaask.com</a> and we will ensure that the account gets removed promptly.</p>
								<p><span onClick={handleUndeleteClick} className="link">Back to edit form</span></p>
							</div>
							)}
							{userComplete && (state.mode === 'redeem') && (
							<div className="send-access">
								<h3>Enter access key</h3>
								<p>
									<input type="text" ref={inputRef} className="access-key" />
									<button onClick={handleTryUnlockKeyClick}>Unlock</button>
								</p>
								<p><span onClick={handleUnlockClick} className="link">Send access key to {state.managerEmail}</span></p>
							</div>
							)}
							<p className="label">Organization details</p>
							<label><span>Organization Name</span> <input type="text" value={state.orgName? state.orgName : ''} name="orgName" onChange={handleChange} placeholder="Organization Name" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<p className="label">Building address</p>
							<label><span>Building Name</span> <input type="text" value={state.buildingName? state.buildingName : ''} name="buildingName" onChange={handleChange} placeholder="Building Name" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>Street Name</span> <input type="text" value={state.streetAddress? state.streetAddress : ''} name="streetAddress" onChange={handleChange} placeholder="Street Name" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>City</span> <input type="text" value={state.city? state.city : ''} name="city" onChange={handleChange} placeholder="City" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>State</span> <input type="text" value={state.state? state.state : ''} name="state" onChange={handleChange} placeholder="State" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>ZIP</span> <input type="text" value={state.postalCode? state.postalCode : ''} name="postalCode" onChange={handleChange} placeholder="ZIP" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>Country</span> <input type="text" value={state.country? state.country : ''} name="country" onChange={handleChange} placeholder="Country" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<p className="label">Manager details</p>
							<label><span>First Name</span> <input type="text" value={state.managerFirstName? state.managerFirstName : ''} name="managerFirstName" onChange={handleChange} placeholder="First Name" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>Last Name</span> <input type="text" value={state.managerLastName? state.managerLastName : ''} name="managerLastName" onChange={handleChange} placeholder="Last Name" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>Phone</span> <input type="text" value={state.managerPhone? state.managerPhone : ''} name="managerPhone" onChange={handleChange} placeholder="Phone" disabled={state.formDisabled? 'disabled' : ''}/></label>
							<label><span>Email</span> <input type="text" value={state.managerEmail? state.managerEmail : ''} name="managerEmail" onChange={handleChange} placeholder="Email" disabled={state.formDisabled? 'disabled' : ''}/></label>
							{state.formDisabled && (
								<p className="settings-delete-link-wrap"><span onClick={handleDeleteClick} className="settings-delete-link">Delete this account</span></p>
							)}
							{!state.formDisabled && (
								<p className="settings-delete-link-wrap"><span onClick={handleDirectDeleteClick} className="settings-delete-link">Delete this account</span></p>
							)}
						</div>
				</main>
				<aside>
					<div id="contact" className="aside-panel center">
						<h2>Contact</h2>
						<p>Reach out to us via email and well get back to you as soon as possible!</p>
						<a href="mailto:help@vaask.com">Email us</a>
					</div>
					<div className="aside-panel aside-panel-dark center" id="download">
						<h2>Download Vaask from the App Store today</h2>
						<p>Set up new dispensers with Vaask by downloading our app</p>
						<div><a href='#body'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="200"/></a></div>
					</div>
				</aside>
			</div>
		</>
	);
};

export default withAuthenticationRequired(SettingsComponent, {
	onRedirecting: () => <Loading />,
});