import React, {useEffect, useState, useRef} from "react";
import { Router, Route, Switch } from "react-router-dom";

import Loading from "./components/Loading";
import NavTray from "./components/NavTray";

import Settings from "./views/Settings";
import Devices from "./views/Devices";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
	const [state, setState] = useState({
		loading: true,
		loadingInitiated: false,
		user: null,
		error: null,
		userComplete:null
	});
	const stateRef = useRef();
	stateRef.current = state;
	
	const { isLoading, error, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } = useAuth0();
	
	var apiRoot = 'api.vaask.com';
	if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' || window.location.hostname === 'portal-dev.vaask.com') {
		apiRoot = 'api-dev.vaask.com';
	}
	
	useEffect(() => {
		(async function login() {
			if (!isLoading && !user) {
				await loginWithRedirect({appState: {returnTo: window.location.pathname}});
			}
			else if (user && state.loadingInitiated === false) {
				setState(prevState => ({
					...prevState,
					loadingInitiated: true,
				}));
				try {
					const token = await getAccessTokenSilently();
					if (token) {
						const response = await fetch(`https://${apiRoot}/api/user`, {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						});

						const responseData = await response.json();
	
						var userComplete = false;
						if (responseData.organization && responseData.organization.orgName) {
							userComplete = true;
						}
		
						setState(prevState => ({
							...prevState,
							loading: false,
							user: responseData,
							userComplete: userComplete,
							error:null,
						}));
					}
				} catch (error) {
					setState(prevState => ({
						...prevState,
						loading: false,
						error: error.error,
					}));
				}
			}
		})();
	}, [isLoading, getAccessTokenSilently, loginWithRedirect, user, state, apiRoot]);
		
	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isLoading) {
		return <Loading />;
	}
	
	const signupProcessor = (data, callback) => {
		return (async () => {
			try {
				var method = (state.user.organization && state.user.organization.orgId)? 'PUT' : 'POST';
				const token = await getAccessTokenSilently();
				const response = await fetch(`https://${apiRoot}/api/organization`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					method: method,
		 			body: JSON.stringify(data)
				});
				
				if (response.status !== 200) {
					callback(false);
					return;
				}
					
				const responseData = await response.json();
				let userComplete = false;
				if (data.orgName) {
					userComplete = true;
				}

				let newUser = structuredClone(stateRef.current.user);
				
				if (!newUser.organization) {
					newUser.organization = {};
				}
				newUser.organization.orgId = responseData.orgId;
				newUser.organization.orgName = data.orgName;
				newUser.organization.buildingName = data.buildingName;
				newUser.organization.streetAddress = data.streetAddress;
				newUser.organization.city = data.city;
				newUser.organization.state = data.state;
				newUser.organization.postalCode = data.postalCode;
				newUser.organization.country = data.country;
				newUser.organization.managerFirstName = data.managerFirstName;
				newUser.organization.managerLastName = data.managerLastName;
				newUser.organization.managerPhone = data.managerPhone;
				newUser.organization.managerEmail = data.managerEmail;
					
				setState({
					...stateRef.current,
					user: newUser,
					userComplete: userComplete
				});
				
				callback(true);
			} catch (error) {
				setState({
					...stateRef.current,
					error: error.error,
				});
				
				callback(false);
			}
		 })();
	};
	
	const deleteProcessor = (validationCode, callback) => {
		// alert(validationCode);
		// callback(false);
		// return;
		
		return (async () => {
			try {
				const token = await getAccessTokenSilently();
				const response = await fetch(`https://${apiRoot}/api/user`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					method: 'DELETE',
					body: JSON.stringify({"validationCode":validationCode})
				});
				if (response.status !== 200) {
					callback(false);
					return;
				}
				callback(true);
			} catch (error) {
				callback(false);
			}
		 })();
	}
	
	const dispatchAccessKey = (callback) => {
		return (async () => {
			try {
				const token = await getAccessTokenSilently();
				const response = await fetch(`https://${apiRoot}/api/organization/send-code`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					method: 'POST'
				});
				callback(response.status === 200);
			} catch (error) {
				callback(false);
			}
		 })();
	}
	
	const checkAccessKey = (accessKey, callback) => {
		// callback(true);
		// return;
		
		return (async () => {
			try {
				const token = await getAccessTokenSilently();
				const response = await fetch(`https://${apiRoot}/api/organization/validate-code`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					method: 'PUT',
					body: JSON.stringify(accessKey)
				});
				callback(response.status === 200);
			} catch (error) {
				callback(false);
			}
		 })();
	}
	
	return (
		<>
			{state.loading && (
				<Loading />
			)}
			{!state.loading && !state.error && state.userComplete && (
				<Router history={history}>
					<div id="app">
						{!isAuthenticated && (
							<Loading />
						)}
						{isAuthenticated && (
							<>
								<NavTray userComplete={state.userComplete} />
								<Switch>
									<Route path="/" exact component={() => <Devices userData={state.user}/>} />
									<Route path="/settings" exact component={() => <Settings userData={state.user} dispatchAccessKey={dispatchAccessKey} checkAccessKey={checkAccessKey} signupProcessor={signupProcessor} userComplete={state.userComplete} deleteProcessor={deleteProcessor} />} />
									<Route path="/settings/:accessKey" component={() => <Settings userData={state.user} dispatchAccessKey={dispatchAccessKey} checkAccessKey={checkAccessKey} signupProcessor={signupProcessor} userComplete={state.userComplete} deleteProcessor={deleteProcessor} />} />
								</Switch>
							</>
						)}
					</div>
				</Router>
			)}
			{!state.loading && !state.error && !state.userComplete && (
				<Router history={history}>
				<div id="app">
				<NavTray userComplete={state.userComplete} />
				<Settings userData={state.user} signupProcessor={signupProcessor} userComplete={state.userComplete} deleteProcessor={deleteProcessor}/>
				</div>
				</Router>
			)}
		</>
	);
};

export default App;